<template>
  <div class="contactUs-floor1">
    <div class="contactUs-floor1-bg">
      <div class="uk-section">
        <div class="uk-container uk-container-large">
          <div uk-grid>
            <div class="uk-width-1-2">
              <div class="contactUs-floor1-title">扫描二维码</div>
              <div class="contactUs-floor1-subtitle">
                专属顾问客服，任何问题，1对1为您解答
              </div>
              <div class="uk-margin-medium">
                <img
                  class="contactUs-floor1-qr"
                  src="@/assets/knowledge_841541d2-9d67-4cca-ad62-3ea780c288ac.jpg"
                />
              </div>
            </div>
            <div class="uk-width-1-2">
              <div class="contactUs-floor1-title">留下您的联系方式</div>
              <div class="contactUs-floor1-subtitle">
                专属顾问会来电与您沟通
              </div>
              <div class="contactUs-floor1-form">
                <form>
                  <div class="uk-margin-medium">
                    <input
                      class="uk-input uk-form-large"
                      type="text"
                      placeholder="姓名"
                    />
                  </div>
                  <div class="uk-margin-medium">
                    <input
                      class="uk-input uk-form-large"
                      type="text"
                      placeholder="手机号"
                    />
                  </div>
                  <div class="uk-margin-medium">
                    <button
                      @click="showQr"
                      type="button"
                      class="uk-button uk-button-danger uk-width-1-1 uk-form-large"
                    >
                      预约专属顾问
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
.contactUs-floor1 {
  background-image: @linear-gradient;
  &-bg {
    background-image: url("~@/assets/bfb8adff-bda6-4ee8-b636-ccc52e267ba6.png");
    background-size: cover;
    padding: 250px 0;
  }
  &-title {
    font-size: 42px;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
  }
  &-subtitle {
    font-size: 26px;
    color: #fff;
    text-align: center;
  }
  .uk-input {
    border-radius: 10px;
  }
  .uk-button-danger {
    border-radius: 10px;
    background: #ff8956;
  }
  &-form {
    width: 60%;
    margin: auto;
    margin-top: 50px;
  }
  &-qr {
    width: 286px;
    height: 286px;
    display: block;
    margin: auto;
  }
}
</style>