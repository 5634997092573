<template>
  <div>
    <floor1 />
  </div>
</template>

<script>
import floor1 from "./components/floor1.vue";
export default {
  components: {
    floor1,
  },
};
</script>

<style>
</style>